import styled from "styled-components";
import { PathImg, size } from "../../Resources/Types";

interface ContainerProps {
    MaxWidth: number;
}

export const Container = styled.div<ContainerProps>`
    margin: 96px 20px 112px 20px;
    max-width: 1280px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
    column-gap: 112px;
    row-gap: 48px;

    /* border: solid black 1px; */
    transition: all 0.4s ease-in;

    @media (max-width: 1280px) {
        column-gap: 109px;
        /* padding-left: 20px;
        padding-right: 20px; */
        max-width: ${props => props.MaxWidth}px;
    }

    @media (max-width: 1040px) {
        width: auto;
        grid-template-columns: 1fr;
        column-gap: 0px;
    }
    @media (max-width: 900px) {
        margin: 20px 0px 112px 0px;
    }
`;

export const ImagView = styled.div<size>`
    /* width: 590px;
    height: 590px; */
    /* width: 100%;
    height: 100%; */
    /* background-image: url(${PathImg} + "/00_Home_View/AboutImage_01.webp"); */
    /* margin-right: 112px; */
    /* border: solid 1px black; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    transition: all 0.4s ease-in;

    /* @media (max-width: 1280px) {
        width: 510px;
        height: 510px;
    }
    @media (max-width: 1040px) {
        width: 450px;
        height: 630px;
    }
    @media (max-width: 900px) {
        width: 450px;
        height: 630px;
    }
    @media (max-width: 600px) {
        width: 350px;
        height: 490px;
    }
    @media (max-width: 400px) {
        width: 300px;
        height: 400px;
    } */
`;

export const DescView = styled.div`
    max-width: 530px;

    /* border: 1px solid red; */
    transition: all 0.4s ease-in;

    @media (max-width: 1280px) {
        max-width: 530px;
    }
    @media (max-width: 900px) {
        max-width: 450px;
    }
    @media (max-width: 600px) {
        max-width: 350px;
    }
    @media (max-width: 400px) {
        max-width: 300px;
    }
`;

interface TitleProps {
    hasMarginBottom?: boolean;
}

export const Title = styled.p<TitleProps>`
    margin: 0px 0px ${props => props.hasMarginBottom ? 36 : 0}px 0px;
    color: var(--primary);
    font-size: 40px;
    /* line-height: 60px; */
    font-family: "Poppins", Times, serif;
    font-weight: 500;
    text-align: left;

    @media (max-width: 1040px) {
        text-align: center;
    }

    @media (max-width: 600px) {
        font-size: 30px;
        margin: 0px 0px ${props => props.hasMarginBottom ? 24 : 0}px 0px;
    }
`;

export const DescText = styled.p`
    color: var(--secondary);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins', Times, serif;
    font-weight: 300;
    text-align: left;
    margin-bottom: 24px;

    @media (max-width: 1040px) {
        text-align: center;
    }

    @media (max-width: 600px) {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 21px;
    }
`;

export const ContainerButton = styled.div`
    display: flex;
    height: auto;
    width: 100%;
    justify-content: flex-start;
    margin-top: 32px;

    /* border: 1px solid green; */

    @media (max-width: 1040px) {
        justify-content: center;
        margin-top: 24px;
    }
`;