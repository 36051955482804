import React, { FC } from "react";
import { BsFacebook, BsWhatsapp, BsInstagram } from "react-icons/bs";
import { motion } from "framer-motion";
import { Content, CoprText, Icon, ItemLeft, ItemRight, ItemText, LogoContainer, LogoImg } from "./styles";
import { PathScreen } from "../../Resources/Types";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../../Resources/Dimensions";
import { Utils } from "../../Resources/Utils";


interface FooterProps {

}

export const Footer: FC<FooterProps> = ({ }) => 
{
    const { width } = useWindowDimensions();
    const location = useLocation();

    return (
        <motion.div
        animate={{
            translateY: location.pathname === PathScreen.MobileMnu ? "100%" : "0%",
            opacity: location.pathname === PathScreen.MobileMnu ? 0 : 1,
            display: location.pathname === PathScreen.MobileMnu ? "none" : "flex"
        }}
            style={{
                minHeight: 152,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                borderTopWidth: 1,
                borderTopStyle: "solid",
                borderTopColor: "#DE5843",
            }}
        >
            <Content>
                <ItemLeft>
                    <ItemText >Cássia Steffaní Fotografias</ItemText>
                    {/* <ItemText bold >Contato: </ItemText>
                    <ItemText>54 99156.4422</ItemText>
                    <ItemText>contato@cassiasteffani.com</ItemText> */}
                </ItemLeft>
                <ItemRight>
                    <a href="https://wa.me/5554991564422?text=Olá,%20Cássia!%20Me%20chamo" target="_blank">
                        <Icon>
                            <BsWhatsapp size={16} color="#DE5843" />
                        </Icon>
                    </a>
                    <a href="https://instagram.com/csteffanifotografias?igshid=YWJhMjlhZTc=" target="_blank">
                        <Icon>
                            <BsInstagram size={16} color="#DE5843" />
                        </Icon>
                    </a>
                    <a href="https://pt-br.facebook.com/csteffanifotografia/" target="_blank">
                        <Icon>
                            <BsFacebook size={16} color="#DE5843" />
                        </Icon>
                    </a>
                </ItemRight>
            </Content>
            <LogoContainer>
                <CoprText>© 2024</CoprText>
                <LogoImg href="https://www.trez.dev" target="_blank" position={Utils.getPositionLg(width)}/>
            </LogoContainer>
        </motion.div>
    );
} 