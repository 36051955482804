import { CtrlMain } from './../Model/CtrlMain';
export default abstract class
{
    public ctrl: CtrlMain;

    constructor(ctrl: CtrlMain)
    {
        this.ctrl = ctrl;
    }

    public abstract Update(): Promise<void>;

    public abstract OnInit(): Promise<void>;

    public abstract DoUpdate(): Promise<void>;
}