import React, { FC } from "react";
import { Link } from "react-router-dom";
import { SysCtrl } from "../../Model";
import { Essay } from "../../Model/Essay/Essay";
import { Utils } from "../../Resources/Utils";
import { Container, Img, SobContainer, SubTitle, Title, Title2 } from "./styles";

interface CardImgProps
{
    imagem: string,
    title: string,
    secondTitle: string,
    subTitle: string,
    to: string,
    job: Essay,
    widthWindow: number; 
}

export const CardImg: FC<CardImgProps> = ({ imagem, title, secondTitle, subTitle, to, job, widthWindow }) =>
{

    return (
        <Link onClick={() => {SysCtrl.essayShowing = job}} to={to + "/" + job.ID} style={{ width: Utils.resizeCard(widthWindow).width, height: Utils.resizeCard(widthWindow).height }}  >
            <Container height={Utils.resizeCard(widthWindow).height} width={Utils.resizeCard(widthWindow).width}>
                <Img url={imagem} height={Utils.resizeCard(widthWindow).height} width={Utils.resizeCard(widthWindow).width} />
                <SobContainer height={Utils.resizeCard(widthWindow).height} width={Utils.resizeCard(widthWindow).width}>
                    <Title>{title}</Title>
                    {
                        secondTitle !== "" &&
                        <Title2>{secondTitle}</Title2>
                    }
                    <SubTitle>{subTitle}</SubTitle>
                </SobContainer>
            </Container>
        </Link>
    );
}