import React, { FC, useEffect, useState } from "react";
import { ActionDiv, Category, FatherGrid, Imgs, LoadContent, NotFound, NotFound2, Title, Title2, TitleContainer } from "./styles";

import { JumpTo } from "../../Components/JumpTo";
import { SysCtrl } from "../../Model";
import { motion } from "framer-motion";
import { PathImg } from "../../Resources/Types";
import useWindowDimensions from "../../Resources/Dimensions";
import { Utils } from "../../Resources/Utils";
import { Essay } from "../../Model/Essay/Essay";
import { useParams } from "react-router-dom";

export const Viewer: FC = () =>
{

    const { width } = useWindowDimensions();
    const [ job, setJob ] = useState<Essay | undefined | null>();
    const [ ArrImgs, setArrImgs ] = useState<Array<any>>();
    const { id } = useParams();

    useEffect(() =>
    {
        async function OnInit()
        {
            // console.log(id);
            const collection = await SysCtrl.GetEssayById(parseInt(id !== undefined ? id : "0"));

            if (collection === null)
            {
                setJob(null);
                return
            }

            setArrImgs(collection.ImageCollection);
            setJob(collection);
        }

        OnInit();

        return () => { }

    }, []);

    return (
        <motion.div
            style={ {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
            } }
            initial={ { opacity: 0 } }
            animate={ { opacity: 1 } }
            exit={ { opacity: 0 } }
        >
            {
                job === null ? (
                    <div style={{ height: "70vh", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                        <NotFound>Ops!</NotFound>
                        <NotFound2>Ensaio não encontrado</NotFound2>
                    </div>
                )
                    : (
                        <>
                            <TitleContainer imgUrl={ job !== undefined ? job.ImgBanner : "" } height={ Utils.resizeBanner(width).height } width={ Utils.resizeBanner(width).width }>
                                <motion.div
                                    style={ {

                                    } }
                                    initial={ { opacity: 0, translateY: "30%" } }
                                    whileInView={ {
                                        opacity: 1,
                                        translateY: "0%",
                                        transition: {
                                            duration: 1,
                                            delay: 0.5,
                                            damping: 20,
                                            type: "spring",
                                            bounce: 50
                                        }
                                    } }
                                // viewport={{ once: true }}
                                >

                                    <Title>{ job?.Name }</Title>
                                    { job?.SubName !== "" &&
                                        <Title2>{ job?.SubName }</Title2>
                                    }
                                    <Category>{ job?.Type }</Category>
                                </motion.div>
                            </TitleContainer>
                            {
                                ArrImgs !== undefined ? (
                                    <div style={ {
                                        width: "100%",
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",

                                        borderStyle: "unset",
                                        boxSizing: "border-box"
                                    } }>
                                        <FatherGrid>
                                            {
                                                ArrImgs.map((element, idx) =>
                                                {
                                                    return (
                                                        <motion.div
                                                            key={ idx }
                                                            style={ {
                                                                gridColumnStart: element.isHorizontal ? 1 : "auto",
                                                                gridColumnEnd: element.isHorizontal ? width < 900 ? 2 : 3 : "auto",
                                                            } }
                                                            initial={ { opacity: 0, translateY: "30%" } }
                                                            whileInView={ {
                                                                opacity: 1,
                                                                translateY: "0%",
                                                                transition: {
                                                                    duration: 1,
                                                                    delay: idx % 2 === 0 ? 0.1 : 0.3,
                                                                    damping: 20,
                                                                    type: "spring",
                                                                    bounce: 50
                                                                }
                                                            } }
                                                            viewport={ { once: true } }>
                                                            <Imgs
                                                                height={ Utils.resizeEssayImg(width, element.isHorizontal).height }
                                                                width={ Utils.resizeEssayImg(width, element.isHorizontal).width }
                                                                imgUrl={ PathImg + element.url }
                                                                isHorizontal={ element.isHorizontal }
                                                            />
                                                        </motion.div>
                                                    )
                                                })
                                            }
                                        </FatherGrid>
                                        <ActionDiv>
                                            <JumpTo />
                                        </ActionDiv>
                                    </div>
                                )
                                    : (
                                        <motion.div
                                            initial={ { opacity: 1 } }
                                            animate={ { opacity: 1 } }
                                            exit={ { opacity: 0 } }>
                                            <FatherGrid>
                                                <LoadContent />
                                                <LoadContent />
                                            </FatherGrid>
                                        </motion.div>
                                    )
                            }

                        </>
                    )
            }
        </motion.div >
    );
}