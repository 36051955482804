import styled from 'styled-components';

export const Title = styled.p`
    position: absolute;
    margin: 0px;
    padding: 0px;
    color: var(--primary);
    font-size: 14px;
    line-height: 21px;
    font-family: 'Poppins', Times, serif;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
`;

export const IconDiv = styled.div`
    position: absolute;
    opacity: 0;
    transition: all 0.2s ease-in-out;
`;

export const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 127px;

    :hover{
        cursor: pointer;
        ${Title}{
            transform: translateY(35%);
        }

        ${IconDiv}{
            transform: translateY(-15%);
            opacity: 1;
        }
    }
`;