import styled from "styled-components";

export const Container = styled.div`
    max-width: 1440px;
    width: 100%;
`;

export const Title = styled.p`
    margin-bottom: 56px;
    margin-left: 180px;
    margin-right: 180px;

    padding: 0px;
    color: var(--primary);
    font-size: 30px;
    line-height: 45px;
    font-family: 'Poppins', Times, serif;
    font-weight: 400;

    /* border: 1px solid red; */

    @media (max-width: 900px) {
        margin-left: 20px;
        margin-right: 20px;
    }
`;

export const JobGrid = styled.div`
/* border: 1px solid black; */
    /* max-width: 915px; */
    width: 100%;
    display: grid;
    gap: 20px 20px;
    
    grid-template-columns: 1fr 1fr 1fr;
    /* grid-template-rows: 1fr 1fr; */
    /* justify-items: center; */
    /* grid-auto-flow: row; */

    
    /* margin-left: 180px;
    margin-right: 180px; */
    margin-bottom: 48px;
    transition: all  0.5s ease-in;

    @media (max-width: 1280px) {
        grid-template-columns: 1fr 1fr;
        /* grid-template-rows: 1fr 1fr; */
        /* max-width: 604px; */

    }
    @media (max-width: 964px) {
        /* grid-template-columns: 1fr;
        grid-template-rows: 1fr; */
        /* margin-left: 20px; */
        /* margin-right: 20px; */
    }
    @media (max-width: 856px) {
        grid-template-columns: 1fr;
        /* grid-template-rows: 1fr; */
        /* margin-left: 20px;
        margin-right: 20px; */
        gap: 20px 0px;
    }
    /* max-width: 915px;
    display: grid;
    gap: 18px 18px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;

    margin-bottom: 48px;


    @media (max-width: 1280px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        max-width: 604px;

    }
    @media (max-width: 964px) {
        margin-left: 20px;
        margin-right: 20px;
    }
    @media (max-width: 520px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        margin-left: 20px;
        margin-right: 20px;
    } */
`;

export const ActionDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 180px;
    margin-right: 180px;
    margin-bottom: 112px;

    @media (max-width: 900px) {
        margin-left: 20px;
        margin-right: 20px;
    }
`;

export const ContainerSelect = styled.div`
    display: flex;
    /* flex-direction: column; */
    /* min-width: 800px; */
    height: 48px;
    margin-bottom: 56px;
    padding-left: 20px;
    padding-right: 20px;
    /* border: solid 1px black; */

    @media (max-width: 900px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    @media (max-width: 521px) {
        display: none;
    }
    
`;

export const ContainerMobileSelect = styled.div`
    display: none;
    /* flex-direction: column; */
    /* min-width: 800px; */
    height: 48px;
    margin-bottom: 56px;
    padding-left: 20px;
    padding-right: 20px;
    /* border: solid 1px red; */

    @media (max-width: 520px) {
        display: flex;
    }
    
`;

interface HB {
    firsth: boolean, 
    end: boolean
}

export const HeaderButtonCont = styled.div<HB>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding-left: ${props => props.firsth ? 0 : 20}px;
    padding-right: ${props => props.end ? 0 : 20}px;

    :hover {
        cursor: pointer;
    }
`;

interface ItemSelectProps {
    show: boolean
}

export const ItemSelect = styled.div<ItemSelectProps>`
    display: ${props => props.show ? "flex" : "none"};
    height: 36px;
    align-items: center;

`

export const TitleSelect = styled.p`
    /* margin-bottom: 56px;
    margin-left: 180px;
    margin-right: 180px; */

    padding: 0px;
    margin: 0px;
    color: var(--primary);
    font-size: 14px;
    font-family: 'Poppins', Times, serif;
    /* border: 1px solid red; */

    /* @media (max-width: 900px) {
        margin-left: 20px;
        margin-right: 20px;
    } */
`;