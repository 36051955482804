import { FC, useEffect, useState } from "react";
import { ContainerMobileSelect, ContainerSelect, HeaderButtonCont, JobGrid, Title, TitleSelect } from "./styles";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { CardImg } from "../../Components/CardImg";
import { Essay } from "../../Model/Essay/Essay";
import { SysCtrl } from "../../Model";
import { motion } from "framer-motion";
import { EssayType, PathScreen } from "../../Resources/Types";
import useWindowDimensions from "../../Resources/Dimensions";

interface JobsProps
{

}

export const Jobs: FC<JobsProps> = ({ }) =>
{
    const { height, width } = useWindowDimensions();
    //const location = useLocation();
    //const { filter } = location.state;
    const filter = EssayType.All;

    const [ Jobs, setJobs ] = useState<Array<Essay>>();
    const [ MaxJobs, setMaxJobs ] = useState<number>(0);
    const [ FilterSelect, setFilterSelect ] = useState<EssayType>(EssayType.All);
    const [ ShowMobileSelect, setShowMobileSelect ] = useState<boolean>(false);
    const [ MobileSelected, setMobileSelected ] = useState<string>("Todos");
    const [ ChangeUpdate, setChangeUpdate ] = useState<boolean>(false);

    const UpdateFilter = (essay: EssayType) =>
    {
        setChangeUpdate(true);

        setTimeout(() => setChangeUpdate(false), 400);

        if (FilterSelect === essay || essay === EssayType.All)
        {
            setFilterSelect(EssayType.All);
            SysCtrl.Filter = EssayType.All;
            setJobs(SysCtrl.ctrlHome.ListEssays);
            return;
        }

        const list = SysCtrl.ctrlHome.GetFilterEssays(essay);

        setFilterSelect(essay);
        SysCtrl.Filter = essay;
        setJobs(list);
    }

    const MobileSelectPress = (essay: EssayType) =>
    {
        UpdateFilter(essay);
        setShowMobileSelect(false);
        switch (essay)
        {
            case EssayType.FemalePortraits:
                setMobileSelected("Femininos");
                break;

            case EssayType.FifteenYears:
                setMobileSelected("15 anos");
                break;

            case EssayType.PregnantWomen:
                setMobileSelected("Gestantes");
                break;

            case EssayType.Repositioning:
                setMobileSelected("Rep. de marca");
                break;
            
                case EssayType.Couple:
                setMobileSelected("Casal");
                break;

            default:
                setMobileSelected("Todos");
                break;
        }

    }

    useEffect(() =>
    {
        async function OnInit()
        {
            let list;
            await SysCtrl.ctrlHome.OnInit();
            // console.log(filter);

            if (SysCtrl.Filter === EssayType.All)
            {
                list = SysCtrl.ctrlHome.ListEssays;
            }
            else
            {
                list = SysCtrl.ctrlHome.GetFilterEssays(SysCtrl.Filter);
            }

            setFilterSelect(SysCtrl.Filter);
            MobileSelectPress(SysCtrl.Filter);
            setJobs(list);
        }

        OnInit();

        return () =>
        {

        };

    }, []);

    return (
        <motion.div
            style={ {
                width: "100%",
                // maxWidth: 1280
                marginBottom: 160
            } }
            initial={ { opacity: 0 } }
            animate={ { opacity: 1 } }
            exit={ { opacity: 0 } }>
            <ContainerSelect>
                <HeaderButton onPress={ () => { UpdateFilter(EssayType.All) } } title="Todos" actived={ FilterSelect === EssayType.All } firsth />
                <HeaderButton onPress={ () => { UpdateFilter(EssayType.FemalePortraits) } } title="Femininos" actived={ FilterSelect === EssayType.FemalePortraits } />
                <HeaderButton onPress={ () => { UpdateFilter(EssayType.FifteenYears) } } title="15 anos" actived={ FilterSelect === EssayType.FifteenYears } />
                <HeaderButton onPress={ () => { UpdateFilter(EssayType.PregnantWomen) } } title="Gestantes" actived={ FilterSelect === EssayType.PregnantWomen } />
                <HeaderButton onPress={ () => { UpdateFilter(EssayType.Repositioning) } } title="Rep. de marca" actived={ FilterSelect === EssayType.Repositioning } end />
                <HeaderButton onPress={ () => { UpdateFilter(EssayType.Couple) } } title="Casal" actived={ FilterSelect === EssayType.Couple } end />
            </ContainerSelect>
            <ContainerMobileSelect>
                <motion.div
                    style={ {
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // borderStyle: "solid",
                        backgroundColor: "#FFF",
                        zIndex: 2,
                    } }
                    initial={ {
                        height: 48,
                    } }
                    animate={ {
                        height: ShowMobileSelect ? 240 : 48,
                    } }
                    transition={ {
                        type: "spring",
                        duration: 1,
                    } }>
                    <motion.div
                        onClick={ () => setShowMobileSelect(prevState => !prevState) }
                        style={ {
                            display: "flex",
                            width: "100%",
                            height: 48,
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottomColor: "#DE5843"
                        } }
                        animate={ {
                            borderBottomWidth: ShowMobileSelect ? 1 : 0,
                            borderBottomStyle: ShowMobileSelect ? "solid" : "none",
                        } }
                        transition={ {
                            type: "spring",
                            delay: 0.2
                        } }
                    >
                        <TitleSelect>{ MobileSelected }</TitleSelect>
                        <motion.div
                            style={ {
                                display: "flex",
                                height: 48,
                                justifyContent: "center",
                                alignItems: "center",
                            } }
                            animate={ {
                                rotate: ShowMobileSelect ? 180 : 0,
                            } }
                            transition={ {
                                type: "spring",
                                delay: 0.2
                            } }>
                            <MdOutlineKeyboardArrowDown size={ 24 } color="#DE5843" />
                        </motion.div>
                    </motion.div>
                    <ItemSelect title="Todos" onPress={ () => MobileSelectPress(EssayType.All) } show={ ShowMobileSelect } />
                    <ItemSelect title="Femininos" onPress={ () => MobileSelectPress(EssayType.FemalePortraits) } show={ ShowMobileSelect } />
                    <ItemSelect title="15 anos" onPress={ () => MobileSelectPress(EssayType.FifteenYears) } show={ ShowMobileSelect } />
                    <ItemSelect title="Gestantes" onPress={ () => MobileSelectPress(EssayType.PregnantWomen) } show={ ShowMobileSelect } />
                    <ItemSelect title="Rep. de marca" onPress={ () => MobileSelectPress(EssayType.Repositioning) } show={ ShowMobileSelect } />
                    <ItemSelect title="Casal" onPress={ () => MobileSelectPress(EssayType.Couple) } show={ ShowMobileSelect } />
                </motion.div>
            </ContainerMobileSelect>
            <div
                style={ {
                    paddingLeft: 20,
                    paddingRight: 20,
                    display: "flex",
                    justifyContent: "center"
                } }>

                <motion.div
                    animate={ { opacity: ChangeUpdate ? 0.4 : 1, translateY: ChangeUpdate ? "100%" : "0%" } }
                    transition={ {
                        duration: 0.2,
                        // delay: 0.1,
                        damping: 20,
                        type: "spring",
                        bounce: 50
                    } }
                >

                    <JobGrid>
                        {
                            Jobs !== undefined && Jobs.map((element, idx) =>
                            {
                                return (
                                    <motion.div
                                        // style={{
                                        //     borderStyle: "solid",
                                        //     boxSizing: "border-box"
                                        // }}
                                        initial={ { opacity: 0, translateY: "30%" } }
                                        whileInView={ {
                                            opacity: 1,
                                            translateY: "0%",
                                            transition: {
                                                duration: 1,
                                                delay: (idx + 0.2) * 0.01,
                                                damping: 20,
                                                type: "spring",
                                                bounce: 50
                                            }
                                        } }
                                        viewport={ { once: true } }
                                    >
                                        <CardImg widthWindow={ width } job={ element } imagem={ element.ImgTumb } title={ element.Name } secondTitle={ element.SubName } subTitle={ element.Type } to={ PathScreen.JobView } key={ idx } />
                                    </motion.div>
                                )
                            })
                        }
                    </JobGrid>
                </motion.div>
            </div>
            {/* <ActionDiv>
                {
                    Jobs?.length !== MaxJobs &&
                    <ButtonClick
                        width={163}
                        height={48}
                        title="Ver mais"
                        click={() => PushCardsTest()}
                    />
                }
            </ActionDiv> */}
        </motion.div>
    );
}

interface HeaderButtonProps
{
    actived: boolean;
    title: string;
    onPress: Function;
    firsth?: boolean;
    end?: boolean;
}

const HeaderButton: FC<HeaderButtonProps> = ({ actived, title, onPress, firsth = false, end = false }) =>
{
    return (
        <HeaderButtonCont onClick={ () => onPress() } end={ end } firsth={ firsth }>
            <motion.text
                style={ {
                    fontSize: 14,
                    fontFamily: 'Poppins',
                    color: "#DE5843"
                } }
                animate={ {
                    opacity: actived ? 1 : 0.5,
                } }
            >
                { title }
            </motion.text>
        </HeaderButtonCont>
    );
}

interface ItemSelectProps
{
    title: string;
    onPress: Function;
    show: boolean;
    icon?: boolean;
}

const ItemSelect: FC<ItemSelectProps> = ({ onPress, title, show, icon = false }) =>
{
    return (
        <motion.div
            onClick={ () => onPress() }
            style={ {
                width: "100%",
                height: 48,
                justifyContent: "space-between",
                alignItems: "center",
                borderBottomWidth: icon ? 1 : 0,
                borderBottomStyle: icon ? "solid" : "none",
                borderBottomColor: "#DE5843"
            } }
            animate={ {
                display: show ? "flex" : "none",
                opacity: show ? 1 : 0,
            } }
            transition={ {
                type: "spring",
                delay: show ? 0.2 : 0
            } }
        >
            <TitleSelect>{ title }</TitleSelect>
        </motion.div>
    );
}