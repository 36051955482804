import { CtrlMain } from "../../Model/CtrlMain";
import { Essay } from "../../Model/Essay/Essay";
import { EssayType } from "../../Resources/Types";
import CtrlScreen from "../CtrlScreen";

export class CtrlHome extends CtrlScreen
{

    public ListEssays: Array<Essay>;

    constructor(ctrl: CtrlMain)
    {
        super(ctrl);
        this.ListEssays = new Array();
    }

    public override async Update(): Promise<void>
    {
        throw new Error("Method not implemented.");
    }

    public override async OnInit(): Promise<void>
    {
        const collection = await this.ctrl.GetEssays();
        this.ListEssays = collection;
    }
    
    public override async DoUpdate(): Promise<void>
    {
        throw new Error("Method not implemented.");
    }

    public async UpdateById(id: number)
    {
        let _id: number = 0;
        for (let i = 0; i < this.ListEssays.length; i++) {
            const element = this.ListEssays[i];
            if (element.ID === id)
            {
                if(!element.Loaded)
                {
                    await element.GetCollection();
                }
                _id = i;
            }
        }

        return this.ListEssays[_id].ImageCollection;
    }

    public GetReverseEssayList()
    {
        let list = this.ListEssays.slice();
        list.reverse();
        return list;
    }

    public GetFilterEssays(type: EssayType)
    {
        let essays: Essay[] = [];
        for (let i = 0; i < this.ListEssays.length; i++) {
            const element = this.ListEssays[i];
            if (element.TypeNumber === type)
            {
                essays.push(element);
            }
        }

        return essays;
    }
    
}