import React, { FC } from "react";
import { Link } from "react-router-dom";
import { EssayType } from "../../Resources/Types";
import { Container, Title } from "./styles";

interface ButtonProps {
    width: number,
    height: number,
    title: string,
    route: string,
    filter?: EssayType
    onPress?: Function
}

export const Button: FC<ButtonProps> = ({ width, height, title, route, filter, onPress = () => {} }) => {
    return (
        <Link onClick={() => onPress()} to={route} style={{ textDecoration: "none" }} state={{ filter: filter }}>
            <Container disabled={false} width={width} height={height} extend={false}>
                <Title>{title}</Title>
            </Container>
        </Link>
    );
}

interface ButtonClickProps {
    width: number | string,
    height: number,
    title: string,
    click: Function,
    extend?: boolean,
    disabled?: boolean,
}


export const ButtonClick: FC<ButtonClickProps> = ({ width, height, title, click, extend = false, disabled = false }) => {
    return (
        <Container
            disabled={disabled}
            onClick={() => {
                if (disabled) return;
                click();
            }}
            width={width}
            height={height}
            extend={extend}>
            <Title>{title}</Title>
        </Container>
    );
}