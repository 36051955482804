import { FC } from "react";
import { motion } from "framer-motion";

interface DotProps
{
    isSelected: boolean,
    maxWidth?: number,
}

export const Dot: FC<DotProps> = ({ isSelected, maxWidth = 1440 }) =>
{
    return (
        <motion.div
            animate={ { 
                backgroundColor: isSelected ? "white" : "transparent",
                transition: {
                    duration: 0.5,
                    damping: 20,
                    type: "spring",
                    bounce: 50
                } 
            } }
            style={ {
                width: maxWidth <= 900 ? 4 : 8,
                height: maxWidth <= 900 ? 4 : 8,
                border: "1px solid white",
                borderRadius: "50%",
                zIndex: 4,
                overflow: "hidden"
            } }
        />
    );
}