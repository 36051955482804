import React, { FC } from "react";
import { Container, IconDiv, Title } from "./styles";
import { IoIosArrowUp } from "react-icons/io";

interface JumpToProps
{

}

export const JumpTo: FC<JumpToProps> = ({  }) =>
{
    const jumpToTop = () =>
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <Container onClick={() => jumpToTop()}>
            <Title>Voltar ao topo</Title>
            <IconDiv>
                <IoIosArrowUp size={18} color="#DE5843" />
            </IconDiv>
        </Container>
    );
}