export class ApiAcess
{
    public static readonly acessPoint: string = "https://api.cassiasteffani.com/";

    public static async ListItem(id: number | string)
    {
        const body = {
            "Id": id.toString()
        }
        const collection = await this.FetchPost("list/photo/param", body);
        
        return collection;
    }
    
    public static async ListTumbEssays()
    {
        const collection = await this.FetchPost("list/dev/essay");
        
        return collection;
    }

    public static async ListImages(prefix: string)
    {
        const body = {
            "prefix": prefix
        }

        const images = await this.FetchPost("list/dev/essay/files2", body);
        return images;
    }

    private static async FetchPost(_path: string, bodyArg?: object)
    {
        const path: string = ApiAcess.acessPoint + _path;
        // console.log(bodyArg)
        // console.log(JSON.stringify(bodyArg))
        try 
        {
            const result = await fetch(path, {
                method: "POST",
                // mode: "cors",
                headers: {
                    //'Accept': 'application/json',
                    "Content-Type": "application/json",
                    //"Access-Control-Allow-Origin": "*"
                },
                body: JSON.stringify(bodyArg)
            });

            const body = await result.json();
            if (body.status! === 200)
            {
                throw Error(body.message);
            }
            return body;


        }
        catch (error)
        {
            console.log(error)
        }

    }
}