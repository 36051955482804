import styled, { keyframes } from "styled-components";
import logo from "../../assets/Cassia_Steffani_Logo_1.png";
import logo2 from "../../assets/Cassia_Steffani_Logo-2.png";

interface ContainerMnuProps
{
    show: boolean
}

export const Container = styled.div<ContainerMnuProps>`
    height: ${props => props.show ? 204 : 96}px;
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease-in;
`;

export const Content = styled.div`
    min-height: 96px;
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* border: solid 1px black; */
`;

export const LogView = styled.div`
    height: 53px;
    width: 163px;
    margin-left: 20px;
    background-repeat: no-repeat;
    background-size: 95% 95%;
    background-position: center;
    background-image: url(${logo});

    /* border: solid 1px black; */

    /* @media (max-width: 1500px) {
        background-image: url(${logo2});
    } */
    @media (max-width: 1200px) {
        margin-left: 20px;
    }
    @media (max-width: 900px){
        margin-left: 16px;
        
    }
`;

export const ActionView = styled.div`
    max-height: 112px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;

    /* border: solid 1px red; */

    @media (max-width: 1200px) {
        margin-right: 20px;
    }
    @media (max-width: 900px) {
        display: none;
    }
`;



export const MnuIcon = styled.div`
    display: none;
 
    @media (max-width: 900px){
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid red; */

        :hover{
            cursor: pointer;
        }
    }
`;

const MnuTransition = keyframes`
    from {
        top: 0px;
        opacity: 0;
    }
    to {
        top: 96px;
        opacity: 1;
    }
`;

export const ContainerMnu = styled.div<ContainerMnuProps>`
    border-bottom: 2px solid #f0eded;
    width: 100%;
    height: auto;
    opacity: ${props => props.show ? 1 : 0};

    /* background-color: #ffffff89; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: all 0.5s ease-in;
`;

export const ButtonBorder = styled.div`
    background-color: var(--primary);
    opacity: 0;
    height: 1px;
    width: 24px;
    transition: 0.3s ease-in;
`;

interface ButtonTitleProps 
{
    fontSize: number;
    upper: boolean;

}

export const ButtonTitle = styled.p<ButtonTitleProps>`
    max-height: 20px;
    margin: 0px;
    padding: 0px;
    color: var(--primary);
    text-transform: ${props => props.upper ? "uppercase" : "none"}; 
    text-decoration: none;
    font-size: ${props => props.fontSize}px;
    line-height: 18px;
    font-family: 'Poppins', Times, serif;
    font-weight: 500;

    text-align: center;
    /* border: solid 1px red; */
`;

export const ButtonView = styled.div<{ isBigger: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 20px;
    min-width: 72px;
    /* width: ${props => props.isBigger ? 110 : 72}px; */
    overflow: visible;
    /* margin-left: 38px; */

    :hover{
        ${ButtonBorder}{
            opacity: 1;
        }
    }

    @media (max-width: 900px) {
        width: 100%;
        height: 36px;
        margin-left: 0px;
    }

    /* border: 1px solid black; */
`;