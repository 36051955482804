import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 1440px;
`;

export const ContainerImg = styled.div`
    position: relative;
    width: 100%;
    height: 848px;
    margin-bottom: 104px;

    overflow: hidden;

    /* @media (max-width: 1280px){
        height: 748px;
    }
    @media (max-width: 900px){
        height: 648px;
    }
    @media (max-width: 800px){
        height: 548px;
    }
    @media (max-width: 700px){
        height: 400px;
    }
    @media (max-width: 600px){
        height: 248px;
    } */

    transition: all  0.3s ease-in;
`;

interface CarouselImgsProps
{
    imgUrl: string
}


export const CarouselImgs = styled.div<CarouselImgsProps>`
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(${props => props.imgUrl});

    transition: background 0.5s ease-in;
`;

export const TitleContainer = styled.div`
    /* position: relative; */
    height: 30px;
    margin-bottom: 64px;
    padding-left: 180px;
    padding-right: 180px;
    display: flex;
    justify-content: center;
    align-items: center;

    /* border: 2px solid black; */

    @media (max-width: 900px) {
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    }
`;

export const TitleContainerP = styled.p`
    padding: 0;
    margin: 0;
    color: var(--primary);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Poppins", Times, serif;

    /* border: 1px solid red; */
`

export const TitleContainerInDiv = styled.div`
    position: absolute;
    /* top: 28px;
    left: 0px; */
    bottom: 0px;
    width: 32px;
    height: 2px;
    background-color: var(--primary);
`

export const JobGrid = styled.div`
    /* border: 1px solid black; */
    /* width: 100%; */
    /* max-width: 915px; */
    display: grid;
    gap: 20px 20px;
    
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    /* grid-template-rows: 1fr 1fr; */
    /* justify-items: center; */
    /* grid-auto-flow: row; */

    
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 48px;
    transition: all  0.3s ease-in;

    @media (max-width: 1280px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        /* max-width: 604px; */
        gap: 20px 20px;

    }
    @media (max-width: 856px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 20px 0px;
        /* margin-left: 20px;
        margin-right: 20px; */
    }
`;

export const ActionDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 180px;
    margin-right: 180px;
    margin-bottom: 160px;

    @media (max-width: 900px) {
        margin-left: 20px;
        margin-right: 20px;
    }
`;

export const Dots = styled.div`
    position: absolute;
    bottom: 32px;
    left: calc(50% - 40px);
    display: flex;
    gap: 16px;

    @media (max-width: 900px) {
        bottom: 8px;
        left: calc(50% - 28px);
    }
`;