import { Essay } from './Essay/Essay';
import { ApiAcess } from './Api/ApiAcess';
import { CtrlHome } from '../Controller/Home/CtrlHome';
import { EssayType } from '../Resources/Types';

export class CtrlMain
{

    ctrlHome: CtrlHome;

    essayShowing!: Essay;

    Filter: EssayType = EssayType.All;

    constructor()
    {
        this.ctrlHome = new CtrlHome(this);
    }


    public async GetEssays(): Promise<Array<Essay>>
    {
        const list = await ApiAcess.ListTumbEssays();
        const collection = new Array<Essay>(list.length);

        // console.log(list);

        for (let i = 0; i < list.length; i++) 
        {
            const element = list[ i ];
            collection[ i ] = new Essay(this, parseInt(element.ImgCard), element.Name, element.Type, "", element.Thumbnail, element.Banner, element.Prefix, element.Priority, element.Imgs)
        }

        return collection;
    }

    public async UpdateEssayCollectionByID(id: number)
    {
        for (let i = 0; i < this.ctrlHome.ListEssays.length; i++) 
        {
            const element = this.ctrlHome.ListEssays[ i ];
            element.GetCollection();
        }
    }


    public async GetEssayById(id: number): Promise<Essay | null>
    {
        const element = await ApiAcess.ListItem(id);


        if (element === undefined) return null;
        return new Essay(this, parseInt(element.ImgCard), element.Name, element.Type, "", element.Thumbnail, element.Banner, element.Prefix, element.Priority, element.Imgs)

    }


}