import styled, { keyframes } from "styled-components";

export const ContentFilter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    padding-left: 180px;
    margin-top: 80px;
    margin-bottom: 112px;
    gap: 20px;

    transition: 0.1s ease-in-out;

    @media(max-width: 1280px){
        justify-content: center;
        padding-left: 0px;
    }
    
    @media (max-width: 600px) {
        padding-left: 20px;
        padding-right: 20px; 
    }
`;


export const SubContainer = styled.div`
    position: absolute;
    bottom: 0px;
    min-height: 366px;
    max-height: 366px;
    max-width: 215px;
    min-width: 215px;
    width: 100%;
    height: 100%;
    background: rgba(222, 88, 67, 0.7);
    z-index: 2;
    transition: 0.4s ease-in;
    opacity: 0;
    /* transform: translateY(100%); */
    
    `; 

export const Title = styled.p`
    width: 100%;
    margin-top: 180px;
    color: var(--white);
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Poppins', Times, serif;
    font-weight: 600;
`;
    
interface ImgFilterProps
{
    imgUrl: string,
}
    
export const ImgFilter = styled.div`
    min-height: 366px;
    max-width: 215px;
    min-width: 215px;
    position: relative;
    overflow: hidden;

    :hover {
        ${SubContainer}{
            opacity: 1;
            /* transform: translateY(0%); */
            cursor: pointer;
        }
    }
`;

export const Img = styled.div<ImgFilterProps>`
    min-height: 366px;
    max-width: 215px;
    min-width: 215px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(${props => props.imgUrl});
`;