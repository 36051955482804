import { motion } from "framer-motion";
import { NotFound1, NotFound2 } from "./styles";

export const NotFound = () => 
{
    return (
        <motion.div
            style={ {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
            } }
            initial={ { opacity: 0 } }
            animate={ { opacity: 1 } }
            exit={ { opacity: 0 } }
        >
            <div style={ { height: "70vh", display: "flex", justifyContent: "center", flexDirection: "column" } }>
                <NotFound1>Ops!</NotFound1>
                <NotFound2>Página não encontrada</NotFound2>
            </div>
        </motion.div>
    );
}