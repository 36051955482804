import React, { FC, useEffect, useState } from "react";

import { CardImg } from "../../Components/CardImg";
import { ActionDiv, CarouselImgs, ContainerImg, Dots, JobGrid, TitleContainer, TitleContainerP } from "./styles";
import { Button } from "../../Components/Button";
import { SysCtrl } from "../../Model";
import { Essay } from "../../Model/Essay/Essay";
import { motion } from "framer-motion";
import { EssayType, PathImg, PathScreen } from "../../Resources/Types";
import useWindowDimensions from "../../Resources/Dimensions";
import { Utils } from "../../Resources/Utils";
import { Dot } from "../../Components/Dot";

interface HomeProps
{

}

export const Home: FC<HomeProps> = ({ }) =>
{
    const { width } = useWindowDimensions();
    const [ Carousel ] = useState<Array<string>>([
        "/00_Home_View/BannerHome_04.webp",
        "/00_Home_View/BannerHome_03.webp",
        "/00_Home_View/BannerHome_02.webp",
        "/00_Home_View/BannerHome_01.webp",
    ]);
    const [ CtrlCrs, setCtrlCrs ] = useState<number>(3);

    const [ Jobs, setJobs ] = useState<Array<Essay>>();

    useEffect(() =>
    {
        async function InitPage()
        {
            await SysCtrl.ctrlHome.OnInit();
            setJobs(SysCtrl.ctrlHome.ListEssays);
        }

        InitPage();

        // console.log("Montou home!");
        const timer = setInterval(() =>
        {
            setCtrlCrs(prevState => prevState <= 0 ? 3 : prevState - 1);
        }, 4000);

        return () =>
        {
            // console.log("Desmontou home!");
            clearInterval(timer);
        };

    }, []);

    return (
        <motion.div
            style={ {
                width: "100%",
            } }
            initial={ { opacity: 0 } }
            animate={ { opacity: 1 } }
            exit={ { opacity: 0 } }>
            <ContainerImg style={ {
                width: Utils.resizeBanner5x2(width).width,
                height: Utils.resizeBanner5x2(width).height
            } }>
                <motion.div animate={ { opacity: CtrlCrs === 0 ? 1 : 0 } }>
                    <CarouselImgs imgUrl={ PathImg + Carousel[ 0 ] } />
                </motion.div>
                <motion.div animate={ { opacity: CtrlCrs === 1 ? 1 : 0 } }>
                    <CarouselImgs imgUrl={ PathImg + Carousel[ 1 ] } />
                </motion.div>
                <motion.div animate={ { opacity: CtrlCrs === 2 ? 1 : 0 } }>
                    <CarouselImgs imgUrl={ PathImg + Carousel[ 2 ] } />
                </motion.div>
                <motion.div animate={ { opacity: CtrlCrs === 3 ? 1 : 0 } }>
                    <CarouselImgs imgUrl={ PathImg + Carousel[ 3 ] } />
                </motion.div>

                <Dots>
                    <Dot isSelected={CtrlCrs === 3} maxWidth={width} />
                    <Dot isSelected={CtrlCrs === 2} maxWidth={width} />
                    <Dot isSelected={CtrlCrs === 1} maxWidth={width} />
                    <Dot isSelected={CtrlCrs === 0} maxWidth={width} />
                </Dots>

            </ContainerImg>
            <TitleContainer>
                <TitleContainerP>Trabalhos em destaque</TitleContainerP>
            </TitleContainer>
            <JobGrid>
                {
                    Jobs !== null && Jobs !== undefined &&
                    Jobs.map((element, idx) =>
                    {
                        if (element.Priority)
                        {
                            return (
                                <motion.div
                                    key={ idx }
                                    style={ {
                                        height: Utils.resizeCard(width).height,
                                        width: Utils.resizeCard(width).width
                                    } }
                                    initial={ { opacity: 0, translateY: "30%" } }
                                    whileInView={ {
                                        opacity: 1,
                                        translateY: "0%",
                                        transition: {
                                            duration: 1,
                                            delay: (idx + 0.2) * 0.01,
                                            damping: 20,
                                            type: "spring",
                                            bounce: 50
                                        }
                                    } }
                                    viewport={ { once: true } }>

                                    <CardImg widthWindow={ width } job={ element } imagem={ element.ImgTumb } title={ element.Name } secondTitle={ element.SubName } subTitle={ element.Type } to={ PathScreen.JobView } key={ idx } />
                                </motion.div>
                            )
                        }
                    })
                }
            </JobGrid>
            <ActionDiv>
                <Button
                    width={ 190 }
                    height={ 48 }
                    title="Ver mais"
                    route={ PathScreen.Jobs }
                    filter={ EssayType.All }
                    onPress={ () => SysCtrl.Filter = EssayType.All }
                />
            </ActionDiv>
        </motion.div>
    );
} 