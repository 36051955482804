import styled from "styled-components";

export const Content = styled.div`
    /* border: solid black 1px; */
    display: grid;
    width: auto;
    gap: 20px 122px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1280px) {
        gap: 20px 122px;
    }
    @media (max-width: 900px){
        gap: 20px 0px;
        grid-template-columns: 1fr;
        width: 541px;
    }
    @media (max-width: 550px){
        width: 447px;
    }
    @media (max-width: 400px){
        width: 300px;
    }
    @media (max-width: 350px){
        width: 260px;
    }
`;

export const DescpView = styled.div`
    /* border: solid black 1px; */

    @media (max-width: 1280px) {

    }
    @media (max-width: 900px){
        max-width: calc(100% - 20px);
    }
    @media (max-width: 400px){
        max-width: 300px;
    }
    @media (max-width: 350px){
        max-width: 260px;
    }
`;

export const HeaderDiv = styled.div`
    border: solid 1px black;
    margin: 96px 180px 48px 180px;

    @media (max-width: 900px){
        margin: 48px 20px 48px 20px;
    }
`;

export const TitleText = styled.p`
    margin: 0px;
    padding: 0px;
    color: var(--primary);
    font-size: 40px;
    /* line-height: 60px; */
    font-family: "Poppins", Times, serif;
    font-weight: 500;

    @media (max-width: 900px){

    }
    @media (max-width: 600px){
        font-size: 30px;
    }
`;

export const SubTitleText = styled.p`
    /* margin: 0px; */
    padding: 0px;
    color: var(--secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 180%;
    font-family: "Poppins", Times, serif;

    @media (max-width: 900px){

    }
    @media (max-width: 600px){
       font-size: 16px;
        line-height: 21px;
    }
`;

export const TextSpan = styled.span`
    color: #1E1E1E;
    font-family: "Poppins", Times, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
`;

export const FormContainer = styled.div`
    /* border: 1px solid black; */
    margin: 0px 180px 112px 180px;

    @media (max-width: 900px){
        margin: 0px 20px 96px 20px;
    }
`;

export const InputLabelText = styled.span`
    position: absolute;
    bottom: 34%;
    left: 0px;
    transition: all 0.3s ease;
    background-color: #FFF;
    padding: 0px 8px 0px 8px;
    transform: translateY(0%);
    font-size: 14px;
    font-family: "Poppins", Times, serif;
    font-weight: 500;

    /* border: solid 2px red; */
`;

export const InputLabel = styled.div`
    position: relative;
    width: 100%;
    height: 64px;
    margin-top: 18px;
    transition: all 0.3s ease; 

    @media (max-width: 900px){
        width: 100%;
    }
`;

export const Input = styled.input`
    width: 100%;
    padding-left: 16px;
    /* padding: 0px 0px 0px 16px; */
    /* border: none; */
    line-height: 64px;
    font-size: 14px;
    font-family: "Poppins", Times, serif;
    font-weight: 400;
    transition: all 0.3s ease;
    border: solid 1px var(--border);
    border-radius: 0px;

    box-sizing: border-box;
       
    :focus{
        outline: none;
    }

    :not(:placeholder-shown){
        border: solid 1px var(--red);
    }
    
    :valid {
        border: solid 1px var(--border);
    }

    @media (max-width: 900px){
        width: 100%;
    }
    
`;



export const InputTitle = styled.label`
/* border: solid 1px black; */
    position: absolute;
    bottom: -2px;
    left: 16px;
    width: 60%;
    height: 98%;
    pointer-events: none;
    transition: all 0.3s ease;
    transform: translateY(0%);

    background-color: white;

    ${Input}:focus ~ &{
        transform: translateY(-55%);
        outline: none;
        background-color: transparent;
        ${InputLabelText}{
            transform: translateX(-20%);
            font-size: 12px;
        }
    }
    
    ${Input}:valid ~ &{
        transform: translateY(-55%);
        outline: none;
        background-color: transparent;
        ${InputLabelText}{
            transform: translateX(-20%);
            font-size: 12px;
        }
    }

    ${Input}:not(:placeholder-shown) ~ &{
        transform: translateY(-55%);
        outline: none;
        background-color: transparent;
        ${InputLabelText}{
            transform: translateX(-20%);
            font-size: 12px;
        }
    }
    
`;



/////

export const FormView = styled.div`
    /* border: solid 1px red; */
    /* width: 590px; */
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* height: 590px; */

    transition: all 0.4s ease-in;

    @media (max-width: 1280px) {
        /* width: 510px; */
        /* height: 510px; */
    }
    @media (max-width: 1040px) {
        /* width: 450px; */
        /* height: 630px; */
    }
    @media (max-width: 900px) {
        width: 541px;
        /* height: 630px; */
    }
    @media (max-width: 550px) {
        /* width: calc(100% - 20px); */
        width: 100%;
        /* height: 490px; */
    }
    @media (max-width: 400px) {
        width: 300px;
    }
    @media (max-width: 350px){
        width: 260px;
    }
`;

export const InputValidText = styled.p`
    line-height: 16px;
    font-size: 12px;
    font-family: "Poppins", Times, serif;
    font-weight: 300;
    color:  var(--red);
`;

////////


export const MsgBoxContaioner = styled.div`
    position: relative;
    width: 100%;
    height: 160px;
    margin-top: 18px;
    border: solid 1px var(--border);
    transition: all 0.3s ease; 
    /* overflow: hidden; */
    box-sizing: border-box;
`;

export const MsgBoxInput = styled.textarea`
    width: calc(100% - 32px);
    height: calc(144px - 8px);
    resize: none;
    padding: 8px 16px 8px 16px;
    border: none;
    line-height: 18px;
    font-size: 14px;
    font-family: "Poppins", Times, serif;
    font-weight: 400;
    transition: all 0.3s ease;

    /* border: solid 1px red; */
       
    :focus{
        outline: none;
    }
`;

export const MsgBoxInputTitleText = styled.span`
    /* display: none; */
    position: absolute;
    top: 8px;
    left: 0px;
    transition: all 0.3s ease;
    background-color: #FFF;
    padding: 0px 8px 0px 8px;
    transform: translateY(0%);
    font-size: 14px;
    font-family: "Poppins", Times, serif;
    font-weight: 500;
    
`;

export const MsgBoxInputTitle = styled.label`
    position: absolute;
    bottom: 0px;
    left: 14px;
    width: 50%;
    height: 100%;
    pointer-events: none;
    transition: all 0.3s ease;
    transform: translateY(0%);

    ${MsgBoxInput}:focus ~ &{
        transform: translateY(-18px);
        outline: none;
        ${MsgBoxInputTitleText}{
            transform: translateX(-15%);
            font-size: 12px;
            display: block;
        }
    }
    
    ${MsgBoxInput}:not(:empty) ~ &{
        transform: translateY(-18px);
        outline: none;
        ${MsgBoxInputTitleText}{
            transform: translateX(-15%);
            font-size: 12px;
            display: block;
        }
    }
    
    
    /* ${MsgBoxInput}:valid ~ &{
        transform: translateY(-18px);
        ${MsgBoxInputTitleText}{
            transform: translateX(-15%);
            font-size: 12px;
        }
    } */
    
`;


/* New Contact */

export const ContactItem = styled.div`
    min-height: 56px;
    width: 100%;
    max-width: 390px;

    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 24px;
`;

export const ContactItemLeft = styled.div`
    width: 32px;
    height: 32px;
`;

export const ContactItemRight = styled.div`
    height: 56px;
    display: flex;
    flex-direction: column;
`;

export const ContactItemRightTitle = styled.p`
    padding: 0;
    margin: 0;
    color: #DE5843;

    font-family: "Poppins", Times, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`;

export const ContactItemRightContent = styled.p`
    padding: 0;
    margin: 0;
    color: #1E1E1E;

    font-family: "Poppins", Times, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
`;