import { size } from "./Types";

export class Utils 
{
    public static readonly RegName: RegExp = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/;
    public static readonly RegCity: RegExp = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s-]+$/;
    public static readonly RegMail: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    public static readonly RegPhone: RegExp = /^\(\d{2}\) \d{5}-\d{4}$/;

    public static isValid(value: string, pattern: RegExp): boolean
    {
        const check = new RegExp(pattern);
        return check.test(value);
    }

    public static resizeCard(widthWindow: number): size
    {
        let width: number = widthWindow - 40
        if (widthWindow > 1280)
        {
            width = (widthWindow - 100) / 3;
        }
        else if (widthWindow <= 1280 && widthWindow > 856)
        {
            width = (widthWindow - 80) / 2;
        }

        return {
            height: (width / 4) * 3,
            width: width
        }
    }

    public static resizeBanner(widthWindow: number): size
    {
        return {
            height: (widthWindow / 16) * 9,
            width: widthWindow
        }
    }
    
    public static resizeBanner5x2(widthWindow: number): size
    {
        return {
            height: (widthWindow / 5) * 2,
            width: widthWindow
        }
    }

    public static resizeEssayImg(widthWindow: number, isHorizontal: boolean): size
    {
        const div: number = isHorizontal ? 1 : 2;
        let width: number = widthWindow;
        
        if(widthWindow > 1280)
        {
            const offSet = widthWindow - 1280;
            const gutter = isHorizontal ? 0 : 20;
            width = (widthWindow - gutter - offSet) / div;
        }
        else if(widthWindow <= 1280 && widthWindow > 900)
        {
            const gutter = isHorizontal ? 60 : 80;
            width = (widthWindow - gutter) / div;
        }
        else 
        {
            const gutter = isHorizontal ? 60 : 60;
            width = (widthWindow - gutter);
        }

        return {
            height: isHorizontal ? (width / 7) * 5 : (width / 5) * 7,
            width: width
        }
    }

    public static getPositionLg(widthWindow: number): number
    {
        let pos: number = widthWindow;
        
        if(widthWindow > 1280)
        {
            const offSet = pos - 1280;
            pos = offSet - (offSet / 2);
        }
        else if(widthWindow <= 1280 && widthWindow > 900)
        {
            pos = 20;
        }
        else 
        {
            pos = 16;
        }

        return pos;
    }
}