import React, { FC } from "react";
import { motion } from "framer-motion";
import { Button } from "../../Components/Button";
import useWindowDimensions from "../../Resources/Dimensions";
import { Utils } from "../../Resources/Utils";
import { Container, ContainerButton, DescText, DescView, ImagView, Title } from "./styles";
import { PathImg, PathScreen } from "../../Resources/Types";

interface AboutProps
{

}

export const About: FC<AboutProps> = ({ }) =>
{
    const { height, width } = useWindowDimensions();

    return (
        <Container MaxWidth={ width - 40 }>
            <motion.div
                animate={ {
                    opacity: 1,
                    translateY: "0%",
                    transition: {
                        duration: 1,
                        damping: 35,
                        type: "spring",
                        bounce: 50
                    }
                } }
                initial={ { opacity: 0, translateY: "100%" } }
                viewport={ { once: true } } >
                <ImagView
                    width={ 0 }
                    height={ 0 }
                    // width={Utils.resizeEssayImg(width, false).width} 
                    // height={Utils.resizeEssayImg(width, false).height}
                    style={ {
                        width: Utils.resizeEssayImg(width, false).width,
                        height: Utils.resizeEssayImg(width, false).height,
                        backgroundImage: `url(${PathImg + "/00_Home_View/AboutImage_02.webp"})`
                    } }
                />
            </motion.div>
            <motion.div
                animate={ {
                    opacity: 1,
                    translateY: "0%",
                    transition: {
                        duration: 1,
                        delay: 0.3,
                        damping: 35,
                        type: "spring",
                        bounce: 50
                    }
                } }
                initial={ { opacity: 0, translateY: "100%" } }
                viewport={ { once: true } }>
                <DescView>
                    <Title>{ "Olá, sou a Cássia," }</Title>
                    <Title hasMarginBottom>{ "a sua fotógrafa" }</Title>
                    <DescText>Nasci no interior da cidade de Ibirapuitã, RS, onde continuo até hoje. Sempre fui apaixonada pelo simples, pelo pôr do sol e tudo que representa a autenticidade. Foi assim, que em 2017, aos 18 anos, iniciei na fotografia. Ali começava uma nova paixão: eternizar histórias e compartilhar momentos.</DescText>
                    <DescText>Toda mulher tem suas próprias características e trajetória, e delas surgem diversas inseguranças, medos e até comparações. Mas saiba que você é ÚNICA.</DescText>
                    {/* <DescText>Meu propósito é retratar a beleza individual de cada uma dessas histórias: o jeito da menina-mulher nos 15 anos, o sentimento mais puro e sincero na linda espera pelo fruto do amor de um casal, a conexão com si própria nos ensaios femininos.</DescText> */ }
                    <DescText>Meu propósito é retratar mulheres incríveis em todas as suas fases. É a partir disso, que eu te convido a viver uma experiência: um momento para chamar de seu, para rir, chorar e viver intensamente.</DescText>
                    <ContainerButton>
                        <Button width={ 255 } height={ 48 } route={ PathScreen.Contact } title="Vamos viver essa experiência?" />
                    </ContainerButton>
                </DescView>
            </motion.div>
        </Container>
    );
} 