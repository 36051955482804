import styled from "styled-components";
import Logo from "../../assets/LgTrez.png";

export const Content = styled.div`
    min-height: 152px;
    width: 100%;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px){
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
`;

export const ItemLeft = styled.div`
    @media (max-width: 1280px){
        margin-left: 20px;
    }
    @media (max-width: 900px){
        padding-top: 40px;
        width: 100%;
        margin-left: 0px;
    }
`;

export const ItemRight = styled.div`
    width: 122px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1280px){
        margin-right: 20px;
    }
    @media (max-width: 900px){
        padding-bottom: 40px;
        margin-top: 24px;
        padding-top: 0px;
        margin-right: 0px;
        justify-content: space-between;
        z-index: 3;
    }
`;

interface ItemTextProps {
    bold?: boolean;
}

export const CoprText = styled.p`
    color: white;
    font-size: 10px;
    font-weight: 400;
    padding: 0px;
    margin: 0px;
    /* height: 32px; */
    font-family: 'Poppins', Times, serif;
    text-align: center;

    /* border: solid 1px black; */

    @media (max-width: 900px){
        text-align: center;
    }
`;

export const ItemText = styled.p<ItemTextProps>`
    color: var(--primary);
    font-size: 14px;
    font-weight: ${props => props.bold ? 600 : 400};
    padding: 0px;
    margin: 0px;
    height: 32px;
    font-family: 'Poppins', Times, serif;
    text-align: start;

    @media (max-width: 900px){
        text-align: center;
    }
`;

export const Icon = styled.div`
    margin-left: 25px;

    @media (max-width: 900px){
        margin-left: 0px;
    }
`;

export const LogoContainer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--primary);
    /* background-color: var(--primary); */
    /* background-color: transparent; */

`;

interface LogoImgProps {
    position: number
}

export const LogoImg = styled.a<LogoImgProps>`
    background-image: url(${Logo});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 44px;
    height: 20px;
    position: absolute;
    /* border: solid 1px black; */
    right: ${props => props.position}px;

    @media (max-width: 900px){
        width: 35px;
        height: 16px;
    }
`;

