import React, { FC } from "react";
import { motion } from "framer-motion";
import { ContentFilter, Img, ImgFilter, SubContainer, Title } from "./styles";
import { Link } from "react-router-dom";
import { EssayType } from "../../Resources/Types";

interface FilterJobProps
{

}

export const FilterJob: FC<FilterJobProps> = ({ }) =>
{
    return (
        <motion.div
            style={{
                width: "100%",
                maxWidth: 1280
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <ContentFilter>
                {/* <Link to={"/Jobs"} state={{ filter: EssayType.FemalePortraits }} >
                    <ImgFilter>
                        <Img imgUrl={Card02} />
                        <SubContainer>
                            <Title>Femininos</Title>
                        </SubContainer>
                    </ImgFilter>
                </Link>
                <Link to={"/Jobs"} state={{ filter: EssayType.PregnantWomen }} >
                    <ImgFilter>
                        <Img imgUrl={Card17} />
                        <SubContainer>
                            <Title>Gestantas</Title>
                        </SubContainer>
                    </ImgFilter>
                </Link>
                <Link to={"/Jobs"} state={{ filter: EssayType.FifteenYears }} >
                    <ImgFilter>
                        <Img imgUrl={Card01} />
                        <SubContainer>
                            <Title>15 anos</Title>
                        </SubContainer>
                    </ImgFilter>
                </Link>
                <Link to={"/Jobs"} state={{ filter: EssayType.Repositioning }} >
                    <ImgFilter>
                        <Img imgUrl={Card18} />
                        <SubContainer>
                            <Title>Reposicionamento de marca</Title>
                        </SubContainer>
                    </ImgFilter>
                </Link> */}
            </ContentFilter>
        </motion.div>
    );
} 