import React, { FC, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ActionView, ButtonBorder, ButtonTitle, ButtonView, LogView, MnuIcon } from "./styles";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { EssayType, PathScreen } from "../../Resources/Types";
import { motion } from "framer-motion";
import { SysCtrl } from "../../Model";

export const Header: FC = () =>
{
    const navigate = useNavigate();
    const location = useLocation();
    const [ MnuShow, setMnuShow ] = useState<boolean>(false);
    const [ Hovered, setHovered ] = useState<boolean>(false);


    // console.log(location.pathname);

    return (
        <motion.div
            animate={ {
                translateY: location.pathname === PathScreen.MobileMnu ? "-100%" : "0%"
            } }
            style={ {
                overflow: "visible",
                height: 112,
                width: "100%",
            } }
        >
            <motion.div
                onMouseLeave={ () => setHovered(false) }
                style={ {
                    padding: 0,
                    margin: 0,
                    position: "absolute",
                    top: 0,
                    minHeight: 112,
                    width: "100%",
                    // maxWidth: 1280,
                    display: "flex",
                    justifyContent: "space-between",
                    zIndex: 3,
                    backgroundColor: "#FFFFFF"
                } }
                initial={ {
                    height: 96
                } }
                animate={ {
                    height: Hovered ? 220 : 96
                } }
                transition={ {
                    type: "spring",
                    stiffness: 50
                } }
            >
                <Link to="/" style={ { height: 112, display: "flex", alignItems: "center" } }>
                    <LogView />
                </Link>
                <ActionView>
                    <ActionButton title="Trabalhos" path={ PathScreen.Jobs } onPress={ () => SysCtrl.Filter = EssayType.All } setHover={ setHovered } isHover={ false } isUppercase filter={ EssayType.All } />
                    <ActionButton title="Sobre mim" path={ PathScreen.About } onPress={ () => { } } setHover={ setHovered } isHover={ false } isUppercase />
                    <ActionButton title="Contato" path={ PathScreen.Contact } onPress={ () => { } } setHover={ setHovered } isHover={ false } isUppercase />
                </ActionView>
                <MnuIcon onClick={ () =>
                {
                    if (MnuShow && location.pathname === PathScreen.MobileMnu) navigate(-1);
                    setMnuShow(prevState => true);
                }
                }>
                    <Link to={ PathScreen.MobileMnu } style={ { display: "flex" } }>
                        { location.pathname === PathScreen.MobileMnu ?
                            <IoMdClose size={ 24 } color="#DE5843" />
                            :
                            <GiHamburgerMenu size={ 24 } color="#DE5843" />
                        }
                    </Link>
                </MnuIcon>
            </motion.div>
        </motion.div>
    );
}

interface ActionButtonProps
{
    title: string,
    path: string,
    onPress: Function,
    setHover: Function,
    isHover: boolean,
    fontSize?: number,
    isUppercase?: boolean,
    isBigger?: boolean,
    filter?: EssayType
}

const ActionButton: FC<ActionButtonProps> = ({ title, path, onPress, setHover, isHover, fontSize = 12, isUppercase = false, isBigger = false, filter }) =>
{
    const location = useLocation();
    function HoverSetEffect(effect: boolean)
    {
        if (isHover)
        {
            if (location.pathname !== PathScreen.Jobs)
            {
                setHover(effect)
            }
        }
    }

    return (
        <Link onClick={ () => onPress() } to={ path } style={ { textDecoration: "none", width: "100%" } } state={ { filter: filter } }>
            <ButtonView
                isBigger={ isBigger }
                onMouseEnter={ () => HoverSetEffect(true) }
            //onMouseLeave={() => !isHover && HoverSetEffect(false)}
            >
                <ButtonTitle fontSize={ fontSize } upper={ isUppercase }>{ title }</ButtonTitle>
                <ButtonBorder />
            </ButtonView>
        </Link>
    );
}