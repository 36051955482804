import { PathImg } from './../../Resources/Types';
import { ApiAcess } from './../Api/ApiAcess';
import { CtrlMain } from './../CtrlMain';
import { EssayType } from "../../Resources/Types";

export class Essay {
    public ctrl: CtrlMain;
    public ID: number;
    public Name: string;
    public SubName: string;
    public Type: string;
    public TypeNumber: EssayType;
    public Description: string;
    public ImgTumb: string;
    public ImgBanner: string;
    public ImageCollection: Array<any>;
    public Priority: boolean;
    public Loaded: boolean;
    private prefix: string;

    constructor(ctrl: CtrlMain, id: number, name: string, type: EssayType, descp: string, tumb: string, banner: string, prefix: string, priority: boolean, collection: Array<any>) {
        this.ctrl = ctrl;
        this.ID = id;

        this.Type = this.TypeString(type);
        this.TypeNumber = type;
        this.Description = descp;
        // this.ImgTumb = this.SetImgTum(parseInt(tumb));
        // this.ImgBanner = this.SetImgBanner(parseInt(tumb));
        this.ImgTumb = PathImg + tumb;
        this.ImgBanner = PathImg + banner;
        this.prefix = prefix;
        this.Priority = priority;
        this.Loaded = false;

        this.ImageCollection = collection;

        if (this.TypeNumber === EssayType.PregnantWomen) {
            const arrName = name.split(" - ");
            if (arrName.length === 2) {
                this.Name = arrName[ 0 ];
                this.SubName = arrName[ 1 ];
            }
            else {
                this.Name = name;
                this.SubName = "";
            }
        }
        else {
            this.Name = name;
            this.SubName = "";
        }

    }

    public async GetCollection() {
        // const collection = await ApiAcess.ListImages(this.prefix);

        // this.ImageCollection = collection;
        return this.ImageCollection;
        this.Loaded = true;
    }

    private TypeString(type: EssayType) {
        switch (type) {
            case EssayType.FifteenYears:
                return "15 anos";
            case EssayType.PregnantWomen:
                return "Gestantes";
            case EssayType.FemalePortraits:
                return "Retratos femininos";
            case EssayType.GraduationEssay:
                return "Ensaio formatura";
            case EssayType.Corporate:
                return "Corporativo";
            case EssayType.Repositioning:
                return "Reposicionamento de marca";
            case EssayType.Couple:
                return "Casal";

            default:
                return "";
        }
    }
}