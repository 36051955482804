import styled from "styled-components";

export const NotFound1 = styled.p`
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin: 0px;
    padding: 0px;

    color: var(--primary);
    font-size: 80px;
    line-height: 120px;
    font-family: 'Poppins', Times, serif;
    font-weight: 600;
    text-align: center;

    @media (max-width: 900px){
        font-size: 16px;
        line-height: 24px;
    }
`;

export const NotFound2 = styled.p`
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin: 0px;
    padding: 0px;

    color: var(--primary);
    font-size: 50px;
    line-height: 60px;
    font-family: 'Poppins', Times, serif;
    font-weight: 450;
    text-align: center;

    @media (max-width: 900px){
        font-size: 12px;
        line-height: 21px;
    }
`;