import styled from "styled-components";

interface ButtonTitleProps 
{
    fontSize: number;
    upper: boolean;

}

export const ButtonTitle = styled.p<ButtonTitleProps>`
    max-height: 20px;
    margin: 0px;
    padding: 0px;
    color: var(--primary);
    text-transform: ${props => props.upper ? "uppercase" : "none"}; 
    text-decoration: none;
    font-size: ${props => props.fontSize}px;
    line-height: 18px;
    font-family: 'Poppins', Times, serif;
    font-weight: 500;

    text-align: center;
    /* border: solid 1px red; */
`;

export const ButtonView = styled.div<{ isBigger: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 64px;
    max-width: 100%;
    /* width: ${props => props.isBigger ? 110 : 72}px; */
    overflow: visible;
    margin-left: 20px;
    margin-right: 20px;

    /* border: 1px solid red; */

`;