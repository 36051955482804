import styled, { keyframes } from "styled-components";

interface TitleImgsProps
{
    imgUrl: string,
    height: number,
    width: number
}

export const TitleContainer = styled.div<TitleImgsProps>`
    width: 100%;
    height: ${props => props.height}px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(${props => props.imgUrl});
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    transition: all 0.4s ease-in;
`;

export const Title = styled.p`
    /* text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); */
    width: 100%;
    margin: 0px;
    padding: 0px;

    color: var(--white);
    text-align: center;
    font-family: 'Poppins', Times, serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: 900px){
        font-size: 16px;
        line-height: 24px;
    }
`;

export const Title2 = styled.p`
    /* text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); */
    width: 100%;
    margin: 0px;
    padding: 0px;

    color: var(--white);
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-family: 'Poppins', Times, serif;
    text-align: center;

    @media (max-width: 900px){
        font-size: 12px;
        line-height: 21px;
    }
`;

export const Category = styled.p`
    /* text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); */
    width: 100%;
    margin: 0px 0px 48px 0px;
    padding: 0px;

    color: var(--white);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 2px;
    font-family: 'Poppins', Times, serif;
    text-transform: uppercase;

    @media (max-width: 900px){
        font-size: 10px;
        line-height: 15px;
        margin: 0px 0px 16px 0px;
    }
`;


export const DescriptionContainer = styled.div``;

export const Description = styled.p`
    margin: 0px 180px 21px 180px;
    padding: 0px;
    color: black;
    font-size: 14px;
    line-height: 21px;
    font-family: 'Poppins', Times, serif;
    font-weight: 300;
`;

export const FatherGrid = styled.div`
    display: grid;
    max-width: 1280px;
    gap: 20px 20px;
    grid-template-columns: 1fr 1fr;
    margin-top: 96px;
    margin-bottom: 96px;
    /* border: 1px solid red; 
    box-sizing: border-box; */
    transition: all 0.4s ease-in;

    @media (max-width: 900px) {
        grid-template-columns: 1fr;
        margin: 48px 0px 96px 0px;
    }
`;

interface ImgsProps
{
    imgUrl: string,
    isHorizontal: boolean,
    height: number,
    width: number,
}


export const Imgs = styled.div<ImgsProps>`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-image: url(${props => props.imgUrl});
    grid-column-start:  ${props => props.isHorizontal ? 1 : "auto"};
    grid-column-end: ${props => props.isHorizontal ? 3 : "auto"};
    transition: all 0.4s ease-in;

    @media (max-width: 900px) {
        grid-column-end: ${props => props.isHorizontal ? 2 : "auto"};
    }
`;

export const ActionDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 160px;
`;

export const LoadContainer = styled.div`
    display: flex;
    width: 100%;
    height: 200px;
    justify-content: center;
    align-items: center;
`

const gradient = keyframes`
    0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

export const LoadContent = styled.div`
    display: flex;
    width: 100%;
    height: 660px;
    justify-content: center;
    align-items: center;

    background: linear-gradient(90deg, #FFF, #d3d3d3, #FFF, #FFF);
	background-size: 400% 400%;
	animation: ${gradient} 2s ease infinite;
`



export const NotFound = styled.p`
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin: 0px;
    padding: 0px;

    color: var(--primary);
    font-size: 80px;
    line-height: 120px;
    font-family: 'Poppins', Times, serif;
    font-weight: 600;
    text-align: center;

    @media (max-width: 900px){
        font-size: 16px;
        line-height: 24px;
    }
`;

export const NotFound2 = styled.p`
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin: 0px;
    padding: 0px;

    color: var(--primary);
    font-size: 50px;
    line-height: 60px;
    font-family: 'Poppins', Times, serif;
    font-weight: 450;
    text-align: center;

    @media (max-width: 900px){
        font-size: 12px;
        line-height: 21px;
    }
`;