import { FC } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Home } from "../Screens/Home";
import { Jobs } from "../Screens/Jobs";
import { About } from "../Screens/About";
import { Contact } from "../Screens/Contact";

import { AnimatePresence } from "framer-motion";
import { FilterJob } from "../Screens/FilterJob";
import { PathScreen } from "../Resources/Types";
import { MobileMnu } from "../Screens/MobileMnu";
import { Viewer } from "../Screens/Viewer";
import { NotFound } from "../Screens/NotFound";

interface AnimationScreensProps
{

}

export const AnimationScreens: FC<AnimationScreensProps> = ({ }) =>
{
    const location = useLocation();

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname} >
                <Route path={PathScreen.Home} element={<Home />}  />
                <Route path={PathScreen.FilterJob} element={<FilterJob />} />
                <Route path={PathScreen.Jobs} element={<Jobs />} />
                <Route path={PathScreen.JobView + "/:id"} element={<Viewer />} />
                <Route path={PathScreen.About} element={<About />} />
                <Route path={PathScreen.Contact} element={<Contact />} />
                <Route path={PathScreen.MobileMnu} element={<MobileMnu />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </AnimatePresence>
    );
}

//className="Container"