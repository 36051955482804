import React, { FC } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Footer } from "../Components/Footer";
import { Header } from "../Components/Header";
import { ScrollToTop } from "../Resources/ScrollToTop";
import { AnimationScreens } from "./AnimationScreens";

interface NavigationProps
{

}

export const Navigation: FC<NavigationProps> = ({ }) =>
{
    return (
        <Router>
            <ScrollToTop />
            <Header />
            <AnimationScreens />
            <Footer />
        </Router>
    );
}

//className="Container"