import React, { FC, useEffect } from 'react';
import { Navigation } from './Navigation';

const App: FC = () =>
{

    // useEffect(() =>
    // {
    //     // async function EffectInit()
    //     // {
    //     //     const res = await fetch("/api/teste");
    //     //     const body = await res.json();
    //     //     if (body.status! == 200)
    //     //     {
    //     //         throw Error(body.message);
    //     //     }

    //     //     console.log(body.express);
    //     // }

    //     // EffectInit();

    //     // return () =>
    //     // {

    //     // }
    // }, []);
    return (
        <Navigation />
    );
}

export default App;
