import React, { FC, HTMLInputTypeAttribute, useState } from "react";
import { motion } from "framer-motion";
import { ClientEmail } from "../../Controller/ClientEmail";
import useWindowDimensions from "../../Resources/Dimensions";
import { EmailData } from "../../Resources/Types";
import
    {
        ContactItem,
        ContactItemLeft,
        ContactItemRight,
        ContactItemRightContent,
        ContactItemRightTitle,
        Content,
        DescpView,
        SubTitleText, TextSpan, TitleText
    } from "./styles";
import { Utils } from "../../Resources/Utils";
import { BsWhatsapp } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";

interface ContactProps
{

}


export const Contact: FC<ContactProps> = ({ }) => 
{
    const { width } = useWindowDimensions();
    const [ Name, setName ] = useState("");
    const [ Email, setEmail ] = useState("");
    const [ Cell, setCell ] = useState("");
    const [ City, setCity ] = useState("");
    const [ Type, setType ] = useState("");
    const [ Msg, setMsg ] = useState("");


    const setFormJson = async () =>
    {
        const clienteEmail: ClientEmail = new ClientEmail();
        const obj: EmailData = {
            name: Name,
            email: Email,
            cellphone: Cell,
            city: City,
            type: Type,
            msg: Msg
        }
        clienteEmail.updateParameters(obj);
        const result: boolean = await clienteEmail.sendEmail();

        if (result)
        {
            setName("");
            setEmail("");
            setCity("");
            setCell("");
            setType("");
            setMsg("");
            alert("Dados enviados com sucesso! \nLogo entraremos em contato. Obrigado!");
            return
        }

        alert("Erro ao enviar os dados!\nPor favor tente novamente.");

    }

    const CheckForm = () =>
    {

        if (!Utils.isValid(Name, Utils.RegName) || !Utils.isValid(Email, Utils.RegMail) || !Utils.isValid(Cell, Utils.RegPhone) || !Utils.isValid(City, Utils.RegCity) || Msg === "")
        {
            return true
        }
        else
        {
            return false
        }
    }

    return (
        <motion.div
            style={ {
                display: "flex",
                justifyContent: "center",
                width: width - 40,
                maxWidth: 1280,
                marginTop: width < 900 ? 24 : 96,
                marginBottom: 160,
                minHeight: "calc(100vh - 274px - 284px)"
            } }
            initial={ { opacity: 0 } }
            animate={ { opacity: 1 } }
            exit={ { opacity: 0 } }
        >
            <Content>
                <motion.div
                    animate={ {
                        opacity: 1,
                        translateY: "0%",
                        transition: {
                            duration: 1,
                            delay: 0.1,
                            damping: 35,
                            type: "spring",
                            bounce: 50
                        }
                    } }
                    initial={ { opacity: 0, translateY: "100%" } }
                    viewport={ { once: true } }
                >
                    <DescpView>
                        <TitleText>Contato</TitleText>
                        <SubTitleText>Vamos capturar a sua <TextSpan>essência feminina</TextSpan> em imagens que transcendem o tempo? </SubTitleText>
                        <SubTitleText>Juntas, viveremos uma experiência e transformaremos cada instante em uma obra de arte que contará <TextSpan> a história da beleza que existe em todas as fases da sua vida.</TextSpan></SubTitleText>
                    </DescpView>
                </motion.div>

                <motion.div
                    style={ {
                        display: "flex",
                        flexDirection: "column",
                        gap: 40,
                        alignItems: "flex-end"
                    } }
                    animate={ {
                        opacity: 1,
                        translateY: "0%",
                        transition: {
                            duration: 1,
                            delay: 0.3,
                            damping: 35,
                            type: "spring",
                            bounce: 50
                        }
                    } }
                    initial={ { opacity: 0, translateY: "100%" } }
                    viewport={ { once: true } }>
                    <ItemContact title="WhatApp" content="54 99156.4422" icon={ 0 } />
                    <ItemContact title="Onde estou" content="Ibirapuitã, RS" icon={ 1 } />
                </motion.div>
            </Content>
        </motion.div>
    );
}

interface ItemContactProps
{
    title: string;
    content: string;
    icon: number;
}

const ItemContact = ({ title, content, icon }: ItemContactProps) =>
{

    const GetIcon = () => 
    {
        if (icon === 0)
        {
            return <BsWhatsapp size={ 32 } color="#DE5843" />
        }

        return <IoLocationOutline size={ 32 } color="#DE5843" />

    }

    return (
        <ContactItem>
            <ContactItemLeft>
                { GetIcon() }
            </ContactItemLeft>
            <ContactItemRight>
                <ContactItemRightTitle>{ title }</ContactItemRightTitle>
                <ContactItemRightContent>{ content }</ContactItemRightContent>
            </ContactItemRight>
        </ContactItem>
    );
}