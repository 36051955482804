import styled from 'styled-components';

interface ContainerProps
{
    width: number | string,
    height: number,
    extend: boolean,
    disabled: boolean
}

export const Container = styled.div<ContainerProps>`
    opacity: ${props => props.disabled ? 0.4 : 1};
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => typeof props.width === "string" ? props.width : props.width + "px"};
    height: ${props => props.height}px;
    margin-top: 27px;
    border-width: 2px;
    border-color: var(--primary);
    box-sizing: border-box;
    border-style: solid;
    color: var(--primary);
    font-size: 14px;
    line-height: 21px;
    font-family: 'Poppins', Times, serif;
    font-weight: 600;
    transition: 0.3s ease-in;

    :hover{
        background-color: ${props => props.disabled ? "var(--white)" : "var(--primary)"};
        color: ${props => props.disabled ? "var(--primary)" : "var(--white)"};
        transition: 0.1s ease-in;
    }

    @media (max-width: 900px){
        width: ${props => props.extend ? "100%" : props.width + "px"};
    }
`

export const Title = styled.p`
    text-decoration: none;
    text-transform: none;
    font-size: 14px;
    line-height: 21px;
    font-family: 'Poppins', Times, serif;
    font-weight: 600;
`