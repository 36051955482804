import React, { FC } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { EssayType, PathScreen } from "../../Resources/Types";
import { ButtonTitle, ButtonView } from "./styles";
import { IoMdClose } from "react-icons/io";
import useWindowDimensions from "../../Resources/Dimensions";
import { SysCtrl } from "../../Model";

export const MobileMnu = () => {
    const { height, width } = useWindowDimensions();
    const navigate = useNavigate();

    return (
        <motion.div
            initial={{ opacity: 0, translateY: "100%" }}
            animate={{ opacity: 1, translateY: "0%" }}
            exit={{ opacity: 0, translateY: "100%" }}
            transition={{
                duration: 0.5,
                damping: 20,
                type: "spring",
                bounce: 30
            }}
            style={{
                position: "absolute",
                top: 0,
                width: "100%",
                maxWidth: 1280,
                minHeight: 500,
                height: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 20,
                // marginTop: 80,
                // marginBottom: 180,
                // borderStyle: "solid"
            }}
        >
            <Link onClick={() => navigate(-1)} to={PathScreen.MobileMnu}
                style={{
                    display: "flex",
                    height: 96,
                    width: width - 20 - 16,
                    // borderStyle: "solid", 
                    justifyContent: "flex-end",
                    alignItems: "center",
                    // marginRight: 16 
                }}>
                <IoMdClose size={24} color="#DE5843" />
            </Link>
            <MnuLink onPress={()=> {}} path={PathScreen.Home} title="Home" />
            <MnuLink onPress={()=> SysCtrl.Filter = EssayType.All} path={PathScreen.Jobs} title="Trabalhos" filter={EssayType.All} />
            <MnuLink onPress={()=> {}} path={PathScreen.About} title="Sobre mim" />
            <MnuLink onPress={()=> {}} path={PathScreen.Contact} title="Contato" />

        </motion.div>
    );
}


interface MnuLinkProps {
    path: string;
    title: string;
    filter?: EssayType;
    onPress: Function;
}

const MnuLink: FC<MnuLinkProps> = ({ path, title, filter, onPress }) => {
    return (
        <Link onClick={() => onPress()} to={path} style={{ textDecoration: "none" }} state={{ filter: filter }}>
            <ButtonView isBigger={false}>
                <ButtonTitle fontSize={20} upper={true}>
                    {title}
                </ButtonTitle>
            </ButtonView>
        </Link>
    );
}