import styled from 'styled-components';


interface ImgProps
{
    url?: string,
    height: number,
    width: number
}

export const Img = styled.div<ImgProps>`
    display: flex;

    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(${props => props.url});
    min-width: 399px;
    width: ${props => props.width}px;
    height: ${props => props.height}px;

    /* border: solid 1px black; */
    transition: all  0.1s ease-in;

    @media (max-width: 439px) {
        min-width: 293px;
        min-height: 223px;
    }
`;

export const SobContainer = styled.div<ImgProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    /* min-width: 399px; */
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    /* min-width: 399px;
    min-height: 305px;
    max-width: 399px;
    max-height: 305px; */
    /* min-width: 293px;
    min-height: 223px;
    max-width: 293px;
    max-height: 223px; */
    /* width: 100%;
    height: 100%; */
    background: rgba(222, 88, 67, 0.7);
    z-index: 2;
    transition: 0.4s ease-in;
    opacity: 0;

    transition: all  0.1s ease-in;

    @media (max-width: 439px) {
        min-width: 293px;
        min-height: 223px;
        /* max-width: 293px;
        max-height: 223px; */
    }
`;

export const Title = styled.p`
/* border: 1px solid black; */
    width: 100%;
    margin: 12px 0 8px 0;
    /* margin-top: 122px; */
    /* margin-bottom: 12px; */
    color: var(--white);
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Poppins', Times, serif;
    font-weight: 600;
`;

export const Title2 = styled.p`
/* border: 1px solid black; */
margin: 0 0 8px 0;
    width: 100%;
    /* margin-top: 122px; */
    /* margin-bottom: 12px; */
    color: var(--white);
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    font-family: 'Poppins', Times, serif;
    font-weight: 500;
`;

export const SubTitle = styled.p`
/* border: 1px solid black; */

    width: 100%;
    margin: 0 0 8px 0;
    color: var(--white);
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Poppins', Times, serif;
    font-weight: 300;
    text-transform: uppercase;
`;

export const Container = styled.div<ImgProps>`
width: ${props => props.width}px;
    height: ${props => props.height}px;
    /* width: 100%;
    height: 100%; */
    /* min-height: 305px; */
    position: relative;
    overflow: hidden;

    /* border: solid 1px green; */

    /* box-sizing: border-box; */

    :hover {
        ${SobContainer}{
            opacity: 1;
            /* transform: translateY(0%); */
            cursor: pointer;
        }
    }
`;